/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderEventChannel {
    PROVIDER_PORTAL = 'PROVIDER_PORTAL',
    PATIENT_PORTAL = 'PATIENT_PORTAL',
    ADMIN_PORTAL = 'ADMIN_PORTAL',
    REFERRALS_PORTAL = 'REFERRALS_PORTAL',
    AUTOBOOK_HC_REFERRAL = 'AUTOBOOK_HC_REFERRAL',
    ZOCDOC = 'ZOCDOC',
    HEALTHCARE_REFERRAL = 'HEALTHCARE_REFERRAL',
    EXTERNAL_CALENDAR = 'EXTERNAL_CALENDAR',
    DOCASAP = 'DOCASAP',
    ZELIS_HCSC = 'ZELIS_HCSC'
}


export const ProviderEventChannelMetadata: EnumMetadata<ProviderEventChannel> = {
    name: 'ProviderEventChannel',
    values: {
            PROVIDER_PORTAL: 'PROVIDER_PORTAL',
            PATIENT_PORTAL: 'PATIENT_PORTAL',
            ADMIN_PORTAL: 'ADMIN_PORTAL',
            REFERRALS_PORTAL: 'REFERRALS_PORTAL',
            AUTOBOOK_HC_REFERRAL: 'AUTOBOOK_HC_REFERRAL',
            ZOCDOC: 'ZOCDOC',
            HEALTHCARE_REFERRAL: 'HEALTHCARE_REFERRAL',
            EXTERNAL_CALENDAR: 'EXTERNAL_CALENDAR',
            DOCASAP: 'DOCASAP',
            ZELIS_HCSC: 'ZELIS_HCSC'
    }
}
