/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
import {ModelMetadata} from '../metadata'

export interface InsuranceReadinessIssueFrozenForAutopayRecoups {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: InsuranceReadinessIssueFrozenForAutopayRecoupsType;

}


    export enum InsuranceReadinessIssueFrozenForAutopayRecoupsType {
            FROZEN_FOR_AUTOPAY_RECOUPS_CX_ACTION = 'FROZEN_FOR_AUTOPAY_RECOUPS_CX_ACTION'
    }


export const InsuranceReadinessIssueFrozenForAutopayRecoupsMetadata: ModelMetadata<InsuranceReadinessIssueFrozenForAutopayRecoups> = {
    name: 'InsuranceReadinessIssueFrozenForAutopayRecoups',
    properties: {
            impact: {
                title: 'Impact',
                type: 'ReadinessIssueImpact',
                
                isEnum: true
            },
            metadata: {
                title: 'ReadinessIssueMetadata',
                type: 'object',
                
                isEnum: false
            },
            type: {
                title: 'Type',
                type: 'string',
                
                isEnum: true
            },
    }
}
