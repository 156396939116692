/* eslint-disable no-fallthrough */
import { Skeleton } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ContentText } from '@headway/helix/ContentText';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { IconIdentificationCard } from '@headway/helix/icons/IdentificationCard';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { OPTUM_COMMERCIAL_IDS } from '@headway/shared/constants/carrierIds';
import { useInsuranceAuthorizations } from '@headway/shared/hooks/useInsuranceAuthorizations';
import { usePatientGeneralPriceEstimate } from '@headway/shared/hooks/usePatientGeneralPriceEstimate';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getPriceString } from '@headway/shared/utils/bookingUtils';

import { IAuthStore } from '../../../stores/withStores';

interface UserInsuranceDetailsProps {
  insurance: UserInsuranceRead;
  AuthStore: IAuthStore;
  providerId?: number;
  hideEstimatedCost?: boolean;
}

export function UserInsuranceDetails({
  insurance,
  AuthStore,
  providerId,
  hideEstimatedCost = false,
}: UserInsuranceDetailsProps) {
  const user = AuthStore.user;
  const isBelowTabletMedia = useMediaQuery(theme.__futureMedia.below('tablet'));
  const isInOldDataOutage = insurance.isInOldDataOutage;

  const { data: estimatedPrice, isLoading: estimatedPriceIsLoading } =
    usePatientGeneralPriceEstimate(user.id, {
      enabled: insurance.isInOutage,
    });

  // current time rounded to the nearest minute
  const now = new Date();
  now.setSeconds(0, 0);

  const queryParams = {
    user_insurance_id: insurance.id,
    date_of_service: now.toISOString(),
    has_unused_sessions: true,
  };

  const activeEapAuthorizationsQuery = useInsuranceAuthorizations(
    {
      query: queryParams,
    },
    {
      select: (authorizations) => {
        return authorizations.filter(
          (auth) =>
            auth.isEapAuthorization && moment(auth.expirationDate).isAfter(now)
        );
      },
    }
  );

  useEffect(() => {
    if (isInOldDataOutage && insurance)
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          patientUserId: insurance.userId,
          userInsuranceId: `${insurance.id}`,
          prelimPricingType: `${insurance.latestOutageType}`,
          copyVariant: '',
          presumptiveAccumulatorsApplied:
            insurance.latestOutage?.accumulatorsPresumedReset ?? [],
        },
      });
  }, [isInOldDataOutage, insurance]);

  const payerName = (
    <div className="flex items-center gap-2">
      <IconIdentificationCard />
      <ContentText variant="body/medium">
        {insurance.frontEndCarrierName}
      </ContentText>
    </div>
  );

  let payerPricingInfo = null;
  if (!providerId || hideEstimatedCost) {
    payerPricingInfo = payerName;
  } else {
    if (estimatedPriceIsLoading) {
      payerPricingInfo = (
        <Skeleton variant="rectangular" height={22} width="100%" />
      );
    } else if (estimatedPrice) {
      const priceString = getPriceString(
        estimatedPrice.min,
        estimatedPrice.max
      );
      if (isBelowTabletMedia) {
        // show estimated cost with link to HC article using mobile spacing and sizing
        payerPricingInfo = (
          <div className="flex gap-2">
            <div>
              <IconIdentificationCard />
            </div>
            <div>
              <strong>
                With {insurance.frontEndCarrierName}, your{' '}
                <GlossaryTerm term="estimated cost">
                  Your estimated cost might change as we learn more details
                  about your coverage, such as whether you've met your
                  deductible.
                </GlossaryTerm>{' '}
                is {priceString} per session.{' '}
              </strong>
            </div>
          </div>
        );
      } else {
        // show estimated cost with link to HC article using non mobile spacing and sizing
        payerPricingInfo = (
          <div className="flex gap-2">
            <div>
              <IconIdentificationCard />
            </div>
            <strong>
              With {insurance.frontEndCarrierName}, your{' '}
              <GlossaryTerm term="estimated cost">
                Your estimated cost might change as we learn more details about
                your coverage, such as whether you've met your deductible.
              </GlossaryTerm>{' '}
              is {priceString} per session.{' '}
            </strong>
          </div>
        );
      }
    } else {
      // if error generating price, just show the payer name
      payerPricingInfo = payerName;
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div
        css={{
          ...theme.stack.horizontal,
          gap: theme.spacing.x2,
          alignItems: 'center',
        }}
      >
        {payerPricingInfo}
      </div>
      <div>
        {insurance.memberId && (
          <div className="flex flex-col gap-2">
            <ContentText variant="body-small" color="foreground/secondary">
              Member ID
            </ContentText>
            <ContentText>{insurance.memberId}</ContentText>
          </div>
        )}
        {insurance.groupNumber && (
          <div className="flex flex-col gap-2">
            <ContentText variant="body-small" color="foreground/secondary">
              Group number
            </ContentText>
            <ContentText>{insurance.groupNumber}</ContentText>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {!activeEapAuthorizationsQuery.data ? null : activeEapAuthorizationsQuery
            .data.length > 0 ? (
          activeEapAuthorizationsQuery.data.map((authorization) => {
            const isOptum = OPTUM_COMMERCIAL_IDS.includes(
              insurance.frontEndCarrierId
            ); //TODO SC-263399
            const remainder = calculateRemainingEapSessions(authorization);

            return (
              <div className="flex flex-col">
                <ContentText>
                  <strong>
                    {isOptum ? 'Optum' : insurance.frontEndCarrierName} Employee
                    Assistance Program (EAP)
                  </strong>
                </ContentText>
                <ContentText>
                  {remainder} of {authorization.validSessions} session
                  {authorization.validSessions !== 1 && 's'} available
                </ContentText>
                <ContentText>
                  Authorization number: {authorization.authorizationNumber}
                </ContentText>
                <ContentText>
                  Expires{' '}
                  {moment(authorization.expirationDate).format('MMM D, YYYY')}
                </ContentText>
              </div>
            );
          })
        ) : (
          <>
            Want to use EAP benefits?{' '}
            <Link
              href="https://findheadway.zendesk.com/hc/en-us/articles/8331047657620-Using-your-Employee-Assistance-Program-EAP-sessions-on-Headway"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

function calculateRemainingEapSessions({
  validSessions,
  pendingSessions,
  usedSessions,
}: InsuranceAuthorizationRead) {
  if (!validSessions) {
    return 0;
  }

  const used = usedSessions ?? 0;
  const pending = pendingSessions ?? 0;

  return validSessions - (pending + used);
}

export function isSelfPayUser(user: UserRead) {
  const providerPatients = user.providerPatients?.slice() ?? [];
  const isSelfPay =
    providerPatients.length > 0 &&
    providerPatients.every(
      (pp) => pp.billingTypeDefault === BillingType.SELF_PAY
    );

  return isSelfPay;
}

export function isInsuranceUser(user: UserRead) {
  const providerPatients = user.providerPatients?.slice() ?? [];
  return (
    providerPatients.length > 0 &&
    providerPatients.some(
      (pp) => pp.billingTypeDefault === BillingType.INSURANCE
    )
  );
}
