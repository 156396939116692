/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { S3SignedUrlGetResponse } from '../models/S3SignedUrlGetResponse';
import { S3SignedUrlPostResponse } from '../models/S3SignedUrlPostResponse';
import { UserUploadTypes } from '../models/UserUploadTypes';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class UserUploadApiResource {

    /**
     * Generate Presigned Get Url
     * 
     * @param url 
     
     */
    public generatePresignedGetUrl(query?: { url: string }, axiosConfig?: AxiosRequestConfig): Promise<S3SignedUrlGetResponse> {
        const apiRoute = '/user-upload/signed-get-url';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Generate Presigned Get Url For Object Key
     * 
     * @param objectKey 
     * @param isPdf 
     
     */
    public generatePresignedGetUrlForObjectKey(query?: { object_key: string, is_pdf?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<S3SignedUrlGetResponse> {
        const apiRoute = '/user-upload/signed-get-url-object-key';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Generate Presigned Post Url
     * 
     * @param type 
     * @param ownerId 
     * @param objectName 
     * @param publicRead 
     * @param unique 
     
     */
    public generatePresignedPostUrl(query?: { type: UserUploadTypes, owner_id: number, object_name: string, public_read?: boolean, unique?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<S3SignedUrlPostResponse> {
        const apiRoute = '/user-upload/signed-post-url';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const UserUploadApi = new UserUploadApiResource();
