import React from 'react';

import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getAvoInsuranceFormContext } from '@headway/shared/utils/InsuranceLookupUtils';
import {
  hasApproachingRemainingSessionsReadinessIssue,
  hasManualVerificationRequiredReadinessIssue,
  hasNoMentalHealthBenefitsReadinessIssue,
  hasNoRemainingSessionReadinessIssue,
  hasNoTelehealthBenefitsReadinessIssue,
  hasOutOfNetworkReadinessIssue,
  hasRemainingSessionsReadinessIssue,
  hasTerminatedReadinessIssue,
  readinessIssuesToAvoIneligibleLookupType,
} from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Modal } from '@headway/ui/Modal';

interface IneligibilityExplainerModalProps {
  user: UserRead | undefined;
  open: boolean;
  onClose: () => void;
  issues: UserAppointmentReadiness['insurance'];
  eligibilityLookup?: EligibilityLookupRead | undefined;
}

export const InsuranceIneligibilityExplainerModal = ({
  user,
  open,
  onClose,
  issues,
  eligibilityLookup,
}: IneligibilityExplainerModalProps) => {
  const formattedPatientFirstName = formatPatientName(user, {
    firstNameOnly: true,
  });
  let updateInsuranceEligibility = true;

  const patientConfirmedAppointments = useQuery(
    ['confirmed-provider-patient-appointments', user!.id],
    () => {
      // no need to pass in provider_id since we are just checking if patient has an appointment with anyone
      return ProviderEventApi.getEvents({
        patient_user_id: user!.id,
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
        expand_estimated_prices: false,
        limit: 1,
        order_by: 'start_date',
        order: 'desc',
      });
    },
    { enabled: !!user && open, staleTime: Infinity }
  );

  React.useEffect(() => {
    if (open && user && user.activeUserInsurance) {
      trackPageView({
        name: 'Ineligible Insurance Modal Viewed',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(user.activeUserInsurance.id),
          eligibilityLookupId:
            eligibilityLookup?.id ||
            user.activeUserInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType:
            readinessIssuesToAvoIneligibleLookupType(issues),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  }, [eligibilityLookup?.id, issues, open, user]);

  const isApproachingMaxSessions =
    hasApproachingRemainingSessionsReadinessIssue(issues);
  const isRemainingSessions = hasRemainingSessionsReadinessIssue(issues);
  const isNoRemainingSessions = hasNoRemainingSessionReadinessIssue(issues);
  const isOON = hasOutOfNetworkReadinessIssue(issues);
  const isTerminated = hasTerminatedReadinessIssue(issues);
  const hasNoMentalHealthBenefits =
    hasNoMentalHealthBenefitsReadinessIssue(issues);
  const isManualVerificationRequired =
    hasManualVerificationRequiredReadinessIssue(issues);
  const hasNoTelehealthBenefits = hasNoTelehealthBenefitsReadinessIssue(issues);

  React.useEffect(() => {
    if (
      user &&
      user.activeUserInsurance &&
      (isNoRemainingSessions || hasNoMentalHealthBenefits)
    ) {
      const eventName = 'Denial Details Modal Viewed';
      const denailType = isNoRemainingSessions
        ? 'max_sessions'
        : 'no_mh_benefits';
      trackPageView({
        name: eventName,
        properties: {
          denialType: [denailType],
          patientUserId: user.id,
          userInsuranceId: String(user.activeUserInsurance.id),
        },
      });
    }
  }, [user, isNoRemainingSessions, hasNoMentalHealthBenefits]);

  if (!user) {
    return null;
  }

  let titleContent;
  let bodyContent;

  const fecName = user.activeUserInsurance?.frontEndCarrierName;
  const fecDisplay = fecName || 'your insurance company';
  let ineligibleIcon = true;

  if (isOON) {
    titleContent = 'Your plan is out-of-network';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            <strong>Your plan is not eligible for in-network care</strong>,{' '}
            based on the details we have today.{' '}
            <Link href="https://headway.co/clients/understanding-health-insurance">
              Learn more about insurance benefits here
            </Link>
            .
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0 leading-4">
            <ContentText variant="body/medium">What you can do</ContentText>
          </h2>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                <strong>Update your insurance info.</strong> Sometimes a plan
                will appear out-of-network because we don’t have the latest
                details from your most recent insurance card.
              </ContentText>
            </li>
            {!!patientConfirmedAppointments.data?.totalCount && (
              <li>
                <ContentText>
                  <strong>Switch to private pay.</strong> If you’re okay paying
                  full cost for sessions, reach out to your provider to let them
                  know.
                </ContentText>
              </li>
            )}
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="m-0 leading-4">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecDisplay} using the number on the back of your
              insurance card to get a reference number regarding your
              eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/21556524888596"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </ContentText>
          </p>
        </div>
        <p className="m-0">
          <ContentText>
            Our team is always working to expand Headway’s coverage with more
            insurers, so even if you're not in-network today, we hope to include
            plans like yours soon!
          </ContentText>
        </p>
      </>
    );
  }

  if (hasNoMentalHealthBenefits) {
    titleContent = 'Your plan doesn’t include mental health coverage';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            <strong>Your plan does not include mental health coverage</strong>,{' '}
            based on the details we have today. This happens when your benefits
            are set up to cover most physical health needs, but do not cover
            behavioral health care, such as therapy sessions.{' '}
            <Link href="/clients/understanding-health-insurance">
              Learn more about insurance benefits here
            </Link>
            .
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">What you can do</ContentText>
          </h2>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                <strong>Update your insurance info.</strong> Sometimes fixing a
                typo or updating to your most recent plan is all it takes to
                resolve the issue. Make sure your name, date of birth and ID
                match your current insurance card exactly.
              </ContentText>
            </li>
            {(patientConfirmedAppointments.data?.totalCount || 0) > 0 && (
              <li>
                <ContentText>
                  <strong>Switch to private pay.</strong> If you’re okay paying
                  full cost for sessions, reach out to your provider to let them
                  know.
                </ContentText>
              </li>
            )}
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecDisplay} using the number on the back of your
              insurance card to get a reference number regarding your
              eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/21556524888596"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </ContentText>
          </p>
        </div>
      </>
    );
  }

  if (isTerminated) {
    titleContent = 'Your plan is no longer active';
    bodyContent = (
      <>
        <p className="m-0">
          <strong>Your plan is no longer active</strong>, based on the details
          we have today.{' '}
          <Link href="https://headway.co/clients/understanding-health-insurance">
            Learn more about insurance benefits here.
          </Link>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              First, update your insurance info
            </ContentText>
          </h2>
          <p className="m-0">
            It’s likely we have old details for a past plan. Update your
            insurance and make to match the details on your most recent
            insurance card.
          </p>
        </div>
        {(patientConfirmedAppointments.data?.totalCount || 0) > 0 ? (
          <div className="flex flex-col gap-2">
            <h2 className="m-0">
              <ContentText variant="body/medium">
                Other options if updating doesn’t solve the issue
              </ContentText>
            </h2>
            <div className="flex flex-col gap-2">
              <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
                <li>
                  <ContentText>
                    <strong>Switch to private pay.</strong> If you’re okay
                    paying full cost for sessions, reach out to your provider to
                    let them know.
                  </ContentText>
                </li>
                <li>
                  <ContentText>
                    <strong>Contact {fecDisplay}</strong> using the number on
                    the back of your insurance card to get a reference number
                    regarding your eligibility. Then{' '}
                    <Link href="/contact" target="_blank">
                      contact our team
                    </Link>{' '}
                    with this number ready.{' '}
                    <Link
                      href="https://help.headway.co/hc/en-us/articles/21556524888596"
                      target="_blank"
                    >
                      Learn more
                    </Link>
                    .
                  </ContentText>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <strong>If it looks like we got it wrong</strong>
            <p className="m-0">
              Contact {fecDisplay} using the number on the back of your
              insurance card to get a reference number regarding your
              eligibility. Then contact our team with this number ready.{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/21556524888596"
                target="_blank"
              >
                Get started
              </Link>
            </p>
          </>
        )}
      </>
    );
  }

  const showManualVerificationRequiredExperience =
    isManualVerificationRequired ||
    (!isTerminated && !isOON && !hasNoMentalHealthBenefits);

  if (showManualVerificationRequiredExperience) {
    titleContent = 'Manual verification required';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            <strong>We can't automatically verify your benefits</strong> based
            on the details we have today.
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">Next steps</ContentText>
          </h2>
          <ol className="m-0 flex list-decimal flex-col gap-2 pl-4">
            <li>
              <ContentText>
                Make sure your insurance details match your current insurance
                card exactly. Sometimes a quick update is all it takes to
                resolve the issue.
              </ContentText>
            </li>
            <li>
              <ContentText>
                Request a manual verification from Headway.{' '}
                <Link href="/contact">Contact our team</Link> to get started.
              </ContentText>
            </li>
          </ol>
        </div>
        <p className="m-0">
          <ContentText>
            Please note it can take 7-15 days to manually verify insurance, but
            rest assured my team will do our best to resolve the issue as
            quickly as possible!
          </ContentText>
        </p>
      </>
    );
  }

  if (hasNoTelehealthBenefits) {
    updateInsuranceEligibility = false;
    titleContent = 'Your plan doesn’t cover virtual sessions';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            <strong>Your plan doesn’t cover virtual sessions</strong>, based on
            the details we have today.
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <ContentText>You can ask your provider to...</ContentText>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                <strong>Switch to in-person sessions</strong>, which are covered
                by your plan.
              </ContentText>
            </li>
            <li>
              <ContentText>
                <strong>Update your billing details to use private pay</strong>{' '}
                instead of insurance, which would allow you to keep attending
                virtual sessions.
              </ContentText>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecDisplay} using the number on the back of your
              insurance card to get a reference number confirming your
              eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.{' '}
              <Link
                href="https://help.headway.co/hc/en-us/articles/21556524888596"
                target="_blank"
              >
                Learn more
              </Link>
              .
            </ContentText>
          </p>
        </div>
      </>
    );
  }

  if (isApproachingMaxSessions) {
    updateInsuranceEligibility = false;
    ineligibleIcon = false;
    titleContent = 'Only a few sessions remaining covered by your plan';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            You’ve used most of the covered sessions for this plan year. Your
            insurer can confirm your exact number of remaining sessions, but our
            records suggest you have fewer than 4 left.
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">What you can do</ContentText>
          </h2>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                If you’d like to attend more sessions in a single plan year, ask
                your provider about private pay
              </ContentText>
            </li>
            <li>
              <ContentText>Wait for your plan year to reset</ContentText>
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecName} using the number on the back of your insurance
              card to get a reference number confirming your eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.
            </ContentText>
          </p>
        </div>
      </>
    );
  }

  if (isNoRemainingSessions) {
    updateInsuranceEligibility = false;
    titleContent = 'No remaining sessions';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            While your plan is eligible for care on Headway, you’ve already used
            all your covered sessions for the current plan year.
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">What you can do</ContentText>
          </h2>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                Ask your provider about private pay, where there is no limit to
                your total number of sessions
              </ContentText>
            </li>
            <li>
              <ContentText>Wait for your plan year to reset</ContentText>
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecName} using the number on the back of your insurance
              card to get a reference number confirming your eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.
            </ContentText>
          </p>
        </div>
      </>
    );
  }

  if (isRemainingSessions) {
    updateInsuranceEligibility = false;
    ineligibleIcon = false;
    titleContent = 'Remaining sessions';
    bodyContent = (
      <>
        <p className="m-0">
          <ContentText>
            Your plan is eligible for care on Headway, and has about{' '}
            {
              user.activeUserInsurance?.latestEligibilityLookup
                ?.remainingCoveredSessions
            }{' '}
            sessions remaining for this plan year. Note that the exact number
            may be different, depending on whether any recent claims are still
            being processed.
          </ContentText>
        </p>
        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">What you can do</ContentText>
          </h2>
          <ul className="m-0 flex list-disc flex-col gap-2 pl-4">
            <li>
              <ContentText>
                Consider adjusting your session frequency based on your total
                covered sessions
              </ContentText>
            </li>
            <li>
              <ContentText>
                If you’d like to attend more sessions in a single plan year, ask
                your provider about private pay
              </ContentText>
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="m-0">
            <ContentText variant="body/medium">
              If something still seems wrong
            </ContentText>
          </h2>
          <p className="m-0">
            <ContentText>
              Contact {fecName} using the number on the back of your insurance
              card to get a reference number confirming your eligibility. Then{' '}
              <Link href="/contact" target="_blank">
                contact our team
              </Link>{' '}
              with this number ready.
            </ContentText>
          </p>
        </div>
      </>
    );
  }

  return (
    <Modal
      title={
        <span className="flex items-center gap-2" role="heading" aria-level={1}>
          <IconWarningCircle
            color={
              ineligibleIcon
                ? theme.color.primary.red
                : theme.color.primary.yellow
            }
          />
          <ContentText variant="body-large/medium">{titleContent}</ContentText>
        </span>
      }
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col gap-4 pb-8">
          <ContentText>Hi {formattedPatientFirstName},</ContentText>
          {bodyContent}
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="flex justify-end gap-2">
          {updateInsuranceEligibility && (
            <LinkButton
              href="/benefits/lookup"
              onClick={onClose}
              variant="secondary"
              size="large"
            >
              Update insurance
            </LinkButton>
          )}
          <Button onPress={onClose} variant="primary" size="large">
            Got it
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
