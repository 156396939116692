import moment from 'moment';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { PHONE_CONSULTATION_LENGTH_IN_MINUTES } from '@headway/shared/constants/availability';
import { BookingAvailabilityEventSelection } from '@headway/shared/types/booking';
import { formatPrice } from '@headway/shared/utils/payments';

import { abbreviationToStateEnum } from '../constants/unitedStatesDisplayNames';
import { getProviderLiveStates } from './ProviderLicenseStatesHelper';
import { getProviderBookableStates } from './providers';
import { logException } from './sentry';

export function getPersonalizedMessage(
  provider: ProviderRead,
  issues: string[] = []
): string {
  const therapist_generalized_type =
    provider.providerType === 'Physician'
      ? 'psychiatrist'
      : provider.providerType === 'Clinical Psychologist'
        ? 'psychologist'
        : provider.providerType === 'Nurse Practitioner'
          ? 'provider'
          : 'therapist';
  let message = `I'm looking for a ${therapist_generalized_type}`;

  const filteredIssues = issues.filter(
    (issue) => issueToUserFacingIssues[issue]
  );

  if (filteredIssues.length) {
    return (message += ` specializing in ${constructJoinIssues(
      issues,
      'and'
    )}.`);
  } else {
    return '';
  }
}

const issueToUserFacingIssues: { [key: string]: string } = {
  anxiety: 'anxiety',
  depression: 'depression',
  eating_disorders: 'eating disorders',
  trauma: 'Post-Traumatic Stress Disorder (PTSD)',
  addiction: 'addiction',
  ocd: 'Obsessive-Compulsive Disorder (OCD)',
  add_adhd: 'ADD/ADHD',
  bipolar: 'bipolar disorder',
  bereavement: 'grief, loss',
  sexual_trauma: 'trauma (sexual)',
  not_sexual_trauma: 'trauma (not sexual)',
  career: 'career issues',
  lgbtq: 'LGBTQIA+ identity',
  family: 'family',
  couples: 'couples',
  coworkers: 'coworkers',
  friends: 'friends',
  other_issue: 'other issues',
};

function constructJoinIssues(arr: string[], joining_symbol: string) {
  const toWriteArray = arr.reduce((toWriteArray: string[], val: string) => {
    const issueDisplay = issueToUserFacingIssues[val];
    if (issueDisplay) {
      toWriteArray.push(issueDisplay);
    }
    return toWriteArray;
  }, []);

  const result = toWriteArray.map((val: string, idx: number) => {
    return idx > 0 && idx === toWriteArray.length - 1
      ? `${joining_symbol} ${val}`
      : val;
  });

  const joined = result.length > 2 ? result.join(', ') : result.join(' ');
  return joined;
}

// TODO(jaymo) move this to higher level utils or aggregate with getPriceStrings in other FEs
interface Options {
  truncate?: boolean;
}
export function getPriceString(
  min?: number,
  max?: number,
  options: Options = {}
) {
  if (min == null || max == null) {
    return '';
  }

  return min === max
    ? formatPrice(min, options.truncate)
    : `${formatPrice(min, options.truncate)}-${formatPrice(
        max,
        options.truncate
      )}`;
}

export const needAdditionalInsuranceInfoFromUser = (user: UserRead) => {
  return (
    !user.activeUserInsurance?.frontEndCarrierId ||
    !user.firstName ||
    !user.lastName ||
    !user.activeUserInsurance?.memberId
  );
};

export const needAdditionalDemographicInfoFromUser = (user: UserRead) => {
  return !user.dob || !user.phone || !user.lastSearchedState;
};

export const needAdditionalInsuranceOrDemographicInfoFromUser = (
  user: UserRead
) => {
  return (
    needAdditionalInsuranceInfoFromUser(user) ||
    needAdditionalDemographicInfoFromUser(user)
  );
};

export const convertAvailabilityToScheduledAppointment = ({
  pendingTimeSlot,
  user,
  provider,
  bookingPreference,
  patientState,
  patientAddresses,
  channel,
  intakeSessionLengthMinutes,
}: {
  pendingTimeSlot:
    | AvailabilityEventResponse
    | BookingAvailabilityEventSelection
    | ConcreteProviderEventRead;
  user: UserRead;
  provider: ProviderRead;
  bookingPreference: ProviderSpecialtyBookingPreference;
  patientState: UnitedStates | undefined;
  patientAddresses: PatientAddressRead[] | undefined;
  intakeSessionLengthMinutes?: number;
  /**
   * The channel of the event. Defaults to PATIENT_PORTAL.
   */
  channel?: ProviderEventChannel;
}) => {
  const requiresIntake =
    bookingPreference === ProviderSpecialtyBookingPreference.INTAKE_CALL;
  const sessionLength = requiresIntake
    ? (intakeSessionLengthMinutes ?? PHONE_CONSULTATION_LENGTH_IN_MINUTES)
    : provider.intakeSessionLengthMinutes;

  const isVirtual = requiresIntake || pendingTimeSlot.telehealth;

  const providerAddressId = isVirtual
    ? null
    : pendingTimeSlot.providerAddressId;

  let providerLicenseStateId = undefined;
  let stateToMatchPLS: UnitedStates | undefined = undefined;

  if (isVirtual) {
    if (patientState) {
      stateToMatchPLS = patientState;
    } else {
      // If we don't have a patientState it means that they were automatically moved
      // past the insurance step and to the checkout step in the booking flow, so we just find
      // an intersection with the provider's pls and use that

      stateToMatchPLS = patientAddresses?.find(
        (address) => address.addressType === PatientAddressType.HOME
      )?.state;

      if (!stateToMatchPLS) {
        const providerStates = getProviderBookableStates(provider);
        stateToMatchPLS = providerStates.find((state) =>
          patientAddresses?.some((address) => address.state === state)
        );
      }
    }
  } else {
    const practiceAddress = provider.providerAddresses?.find(
      (address) => address.id === providerAddressId
    );
    stateToMatchPLS = practiceAddress?.state
      ? (abbreviationToStateEnum[practiceAddress.state] as UnitedStates)
      : undefined;
  }

  if (!stateToMatchPLS && getProviderLiveStates(provider).length > 1) {
    logException(new Error('Unable to find state to match to a PLS'));
  }

  const matchingProviderLicenseState =
    provider.activeProviderLicenseStates.find(
      (pls) => pls.state === stateToMatchPLS
    );

  providerLicenseStateId =
    matchingProviderLicenseState?.id ?? provider.primaryStateId;

  return {
    providerId: pendingTimeSlot.providerId,
    providerLicenseStateId,
    patientUserId: user.id,
    startDate: pendingTimeSlot.startDate!,
    endDate: moment(pendingTimeSlot.startDate)
      .add(sessionLength, 'minutes')
      .toDate()
      .toISOString(),
    timeZone: pendingTimeSlot.timeZone,
    providerAddressId,
    type: requiresIntake
      ? ProviderEventType.INTAKE_CALL
      : ProviderEventType.APPOINTMENT,
    telehealth: pendingTimeSlot.telehealth,
    channel: channel ?? ProviderEventChannel.PATIENT_PORTAL,
    providerAppointment: {
      provider_id: pendingTimeSlot.providerId,
      user_id: user.id,
      status: ProviderAppointmentStatus.SCHEDULED,
    },
  };
};

enum UserSessionMediumDecision {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
}

export { UserSessionMediumDecision };
