import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefits';
import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserAction';
import { InsuranceReadinessIssueHasNoTelehealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueHasNoTelehealthBenefits';
import { InsuranceReadinessIssueInactiveBenefitsType } from '@headway/api/models/InsuranceReadinessIssueInactiveBenefits';
import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { InsuranceReadinessNoRemainingSessionsType } from '@headway/api/models/InsuranceReadinessNoRemainingSessions';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';

const issueToTextMap: Record<string, string> = {
  [InsuranceReadinessIssueTerminatedType.TERMINATED]:
    'Your plan is no longer active.',
  [InsuranceReadinessIssueInactiveBenefitsType.INACTIVE_BENEFITS]:
    'Your plan is no longer active.',
  [InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK]:
    'Your plan is out of network.',
  [InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS]:
    'Your plan does not cover mental health.',
  [InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType.FROZEN_FOR_COB]:
    'It looks like we have your secondary insurance on file. We’ll need your primary insurance to verify your benefits.',
  [InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType.FROZEN_FOR_COB_USER_ACTION]:
    'It looks like we have your secondary insurance on file. We’ll need your primary insurance to verify your benefits.',
  [InsuranceReadinessNoRemainingSessionsType.NO_REMAINING_COVERED_SESSIONS]:
    'You’ve run out of covered sessions on your plan.',
  [InsuranceReadinessIssueHasNoTelehealthBenefitsType.HAS_NO_TELEHEALTH_BENEFITS]:
    'Your plan doesn’t cover virtual sessions.',
  [InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED]:
    'Your plan needs additional verification.',
  // Default message for unmatched issues
  default: 'Your plan is not eligible for in-network care.',
};

const getGuidanceText = (issues: UserAppointmentReadiness['insurance']) => {
  for (const issue of issues) {
    if (issueToTextMap[issue.type]) {
      return issueToTextMap[issue.type];
    }
  }
  return issueToTextMap.default;
};

export const InsuranceIneligibleGuidanceCard = ({
  issues,
}: {
  issues: UserAppointmentReadiness['insurance'];
}) => {
  const guidanceCardText = getGuidanceText(issues);

  return (
    <GuidanceCard variant="error">
      <ContentText>{guidanceCardText}</ContentText>
      <LinkButton href="/benefits" variant="link">
        See more details
      </LinkButton>
    </GuidanceCard>
  );
};
