/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserUploadTypes {
    PROVIDER_UPLOADS = 'PROVIDER_UPLOADS',
    PROVIDER_UPLOADS_PUBLIC = 'PROVIDER_UPLOADS_PUBLIC',
    PROVIDER_PHOTOS = 'PROVIDER_PHOTOS',
    PROVIDER_QUESTIONNAIRE_UPLOADS = 'PROVIDER_QUESTIONNAIRE_UPLOADS',
    PROVIDER_PAYMENTS_DOWNLOADS = 'PROVIDER_PAYMENTS_DOWNLOADS',
    SIMPLE_PRACTICE_IMPORT_UPLOADS = 'SIMPLE_PRACTICE_IMPORT_UPLOADS',
    USER_INSURANCE_CARD_PHOTOS = 'USER_INSURANCE_CARD_PHOTOS',
    CARE_NAVIGATOR_CALL_AUDIO_UPLOADS = 'CARE_NAVIGATOR_CALL_AUDIO_UPLOADS',
    INBOUND_FAXES = 'INBOUND_FAXES'
}


export const UserUploadTypesMetadata: EnumMetadata<UserUploadTypes> = {
    name: 'UserUploadTypes',
    values: {
            PROVIDER_UPLOADS: 'PROVIDER_UPLOADS',
            PROVIDER_UPLOADS_PUBLIC: 'PROVIDER_UPLOADS_PUBLIC',
            PROVIDER_PHOTOS: 'PROVIDER_PHOTOS',
            PROVIDER_QUESTIONNAIRE_UPLOADS: 'PROVIDER_QUESTIONNAIRE_UPLOADS',
            PROVIDER_PAYMENTS_DOWNLOADS: 'PROVIDER_PAYMENTS_DOWNLOADS',
            SIMPLE_PRACTICE_IMPORT_UPLOADS: 'SIMPLE_PRACTICE_IMPORT_UPLOADS',
            USER_INSURANCE_CARD_PHOTOS: 'USER_INSURANCE_CARD_PHOTOS',
            CARE_NAVIGATOR_CALL_AUDIO_UPLOADS: 'CARE_NAVIGATOR_CALL_AUDIO_UPLOADS',
            INBOUND_FAXES: 'INBOUND_FAXES'
    }
}
