import React from 'react';

import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getAvoInsuranceFormContext } from '@headway/shared/utils/InsuranceLookupUtils';
import {
  getHasTrackableReadinessIssue,
  hasHeadwaySourcedHumanInputError,
  hasNoTelehealthBenefitsReadinessIssue,
} from '@headway/shared/utils/insuranceUtils';
import { getHumanInputLookupIssues } from '@headway/shared/utils/insuranceUtils';
import {
  hasApproachingRemainingSessionsReadinessIssue,
  hasManualVerificationRequiredReadinessIssue,
  hasNoMentalHealthBenefitsReadinessIssue,
  hasNoRemainingSessionReadinessIssue,
  hasOutOfNetworkReadinessIssue,
  hasRemainingSessionsReadinessIssue,
  hasTerminatedReadinessIssue,
} from '@headway/shared/utils/insuranceUtils';
import { readinessIssuesToAvoIneligibleLookupType } from '@headway/shared/utils/insuranceUtils';

import { IAuthStore, IUiStore, withStores } from '../stores/withStores';
import { VERIFY_CLAIM_READINESS_QUERY_KEY } from '../utils/cacheKeys';
import { InsuranceIneligibleGuidanceCard } from './Benefits/InsuranceGuidanceCards/InsuranceIneligibleGuidanceCard';
import { getHasUnreadinessIssue } from './Benefits/InsuranceGuidanceCards/utils';
import { InsuranceIneligibilityExplainerModal } from './InsuranceIneligibilityExplainerModal';

export interface InsuranceVerificationAlertImplProps {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  providerId?: number;
}

const InsuranceVerificationAlertImpl = ({
  AuthStore,
  providerId,
}: InsuranceVerificationAlertImplProps) => {
  const user = AuthStore.user;
  const userInsurance = user.activeUserInsurance;

  const appointmentReadinessQuery = useAppointmentReadiness(
    user.id,
    providerId
  );
  const claimReadinessQuery = useQuery(
    [VERIFY_CLAIM_READINESS_QUERY_KEY, user.activeUserInsuranceId],
    () => {
      if (!user.activeUserInsuranceId) {
        return null;
      }
      return UserApi.getClaimReadiness(user.id);
    },
    {
      staleTime: Infinity,
    }
  );
  const claimReadinessRequirements =
    claimReadinessQuery.data?.requirements || [];

  const issues = appointmentReadinessQuery?.data;
  const insuranceIssues = issues?.insurance;

  const humanInputLookupIssues = getHumanInputLookupIssues(issues);
  const isOONUnready = hasOutOfNetworkReadinessIssue(insuranceIssues);
  const isTerminatedUnready = hasTerminatedReadinessIssue(insuranceIssues);
  const isNoMentalHealthBenefitsUnready =
    hasNoMentalHealthBenefitsReadinessIssue(insuranceIssues);
  const isManualVerificationRequiredUnready =
    hasManualVerificationRequiredReadinessIssue(insuranceIssues);
  const hasNoTelehealthBenefitsUnready =
    hasNoTelehealthBenefitsReadinessIssue(insuranceIssues);
  const isRemainingSessions =
    hasRemainingSessionsReadinessIssue(insuranceIssues);
  const isNoRemainingSessions =
    hasNoRemainingSessionReadinessIssue(insuranceIssues);
  const isApproachingMaxSessions =
    hasApproachingRemainingSessionsReadinessIssue(insuranceIssues);

  const [
    isIneligibilityExplainerModalOpen,
    setIsIneligibilityExplainerModalOpen,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      userInsurance &&
      (getHasTrackableReadinessIssue(insuranceIssues) ||
        userInsurance.isInOutage)
    ) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(userInsurance.id),
          eligibilityLookupId: userInsurance.latestEligibilityLookup?.id,
          ineligibleLookupType:
            readinessIssuesToAvoIneligibleLookupType(insuranceIssues),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  }, [userInsurance, user.id, insuranceIssues]);

  if (!userInsurance) {
    return null;
  }

  if (
    claimReadinessRequirements.includes(UserClaimReadinessCheck.LOOKUPS_PAUSED)
  ) {
    return (
      <GuidanceCard variant="warning" layout="vertical">
        <ContentText variant="body/medium">Insurance Systems Down</ContentText>
        <ContentText variant="body">
          Our insurance processing partner is down at the moment, so we’re not
          able to get your plan details right now. Please try again shortly.
          (And don’t worry — this has nothing to do with your personal
          coverage!)
        </ContentText>
      </GuidanceCard>
    );
  }

  const showHumanInputErrorAlert = hasHeadwaySourcedHumanInputError(
    humanInputLookupIssues
  );

  const shouldShowExitOutageToIneligibleAlert =
    !!insuranceIssues &&
    (userInsurance.wasInNoDataOutage || userInsurance.wasInOldDataOutage) &&
    getHasUnreadinessIssue(insuranceIssues);

  if (shouldShowExitOutageToIneligibleAlert) {
    return <InsuranceIneligibleGuidanceCard issues={insuranceIssues} />;
  }

  let view = null;

  if (isOONUnready) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body/medium">
          Your plan is out-of-network
        </ContentText>
        <ContentText variant="body">
          Check your insurance info to make sure it matches the details on your
          most recent insurance card. Still getting this message?{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isTerminatedUnready) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body/medium">
          This plan is no longer active
        </ContentText>
        <ContentText variant="body">
          Check your insurance info to make sure it matches the details on your
          most recent insurance card. Still getting this message?{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isNoMentalHealthBenefitsUnready) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body/medium">
          Your plan does not include mental health coverage
        </ContentText>
        <ContentText variant="body">
          Check your insurance info to make sure it matches the details on your
          most recent insurance card. Still getting this message?{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isManualVerificationRequiredUnready) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body/medium">
          Unable to verify your plan
        </ContentText>
        <ContentText variant="body">
          Check your insurance info to make sure it matches the details on your
          most recent insurance card. Still getting this message?{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (showHumanInputErrorAlert) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body">
          <strong>Likely typo in insurance details.</strong> Check to make sure
          your insurance details match the details on your insurance card.
          Usually, it just means fixing one of the following issues:
          <ul>
            <li>
              Make sure your name is spelled exactly the same way as on your
              card.
            </li>
            <li>Double check your date of birth.</li>
            <li>Confirm your Member ID matches your card exactly.</li>
          </ul>
          Still having trouble? Reach out to your insurer directly.
        </ContentText>
      </GuidanceCard>
    );
  }

  if (hasNoTelehealthBenefitsUnready) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body">
          Your plan doesn’t cover virtual sessions. You can ask your provider to
          switch to in-person sessions or use private pay to continue with
          virtual sessions.{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See details
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isApproachingMaxSessions) {
    view = (
      <GuidanceCard variant="warning" layout="vertical">
        <ContentText variant="body">
          You’ve used most of your covered sessions for this plan year.{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See details
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isNoRemainingSessions) {
    view = (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText variant="body">
          You have no remaining sessions covered by your plan. You can still
          attend sessions by using private pay.{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See Details
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (isRemainingSessions) {
    view = (
      <GuidanceCard variant="warning" layout="vertical">
        <ContentText variant="body">
          Your plan has about{' '}
          {userInsurance?.latestEligibilityLookup?.remainingCoveredSessions}{' '}
          sessions remaining for this plan year.{' '}
          <Button
            variant="link"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See Details
          </Button>
        </ContentText>
      </GuidanceCard>
    );
  }

  if (view) {
    return (
      <>
        <InsuranceIneligibilityExplainerModal
          user={user}
          open={isIneligibilityExplainerModalOpen}
          onClose={() => setIsIneligibilityExplainerModalOpen(false)}
          issues={insuranceIssues || []}
        />
        {view}
      </>
    );
  }
  return null;
};

export const InsuranceVerificationAlert = withStores(
  InsuranceVerificationAlertImpl
);
