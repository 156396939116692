/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserClaimReadinessCheck {
    PATIENT_ADDRESS = 'PATIENT_ADDRESS',
    NO_ELIGIBILITY_LOOKUP = 'NO_ELIGIBILITY_LOOKUP',
    INSUFFICIENT_OR_INCORRECT_INFORMATION = 'INSUFFICIENT_OR_INCORRECT_INFORMATION',
    INSURANCE_INACTIVE = 'INSURANCE_INACTIVE',
    MANUAL_VERIFICATION_REQUIRED = 'MANUAL_VERIFICATION_REQUIRED',
    LOOKUPS_PAUSED = 'LOOKUPS_PAUSED',
    FROZEN_FOR_COB = 'FROZEN_FOR_COB',
    FROZEN_FOR_COB_USER_ACTION = 'FROZEN_FOR_COB_USER_ACTION',
    FROZEN_FOR_TERMED_PLAN = 'FROZEN_FOR_TERMED_PLAN',
    FROZEN_FOR_OON = 'FROZEN_FOR_OON',
    FROZEN_FOR_WAIVED_SESSION_MAX_HIT = 'FROZEN_FOR_WAIVED_SESSION_MAX_HIT',
    FROZEN_FOR_AUTOPAY_CX_ACTION = 'FROZEN_FOR_AUTOPAY_CX_ACTION',
    FROZEN_FOR_OTHER_REASON = 'FROZEN_FOR_OTHER_REASON',
    OUT_OF_NETWORK = 'OUT_OF_NETWORK',
    OON_FOR_PROVIDER_ADDRESS = 'OON_FOR_PROVIDER_ADDRESS',
    TERMINATED = 'TERMINATED',
    HAS_NO_MENTAL_HEALTH_BENEFITS = 'HAS_NO_MENTAL_HEALTH_BENEFITS',
    EAP_OUT_OF_SESSIONS = 'EAP_OUT_OF_SESSIONS',
    EAP_OUT_OF_DATE = 'EAP_OUT_OF_DATE'
}


export const UserClaimReadinessCheckMetadata: EnumMetadata<UserClaimReadinessCheck> = {
    name: 'UserClaimReadinessCheck',
    values: {
            PATIENT_ADDRESS: 'PATIENT_ADDRESS',
            NO_ELIGIBILITY_LOOKUP: 'NO_ELIGIBILITY_LOOKUP',
            INSUFFICIENT_OR_INCORRECT_INFORMATION: 'INSUFFICIENT_OR_INCORRECT_INFORMATION',
            INSURANCE_INACTIVE: 'INSURANCE_INACTIVE',
            MANUAL_VERIFICATION_REQUIRED: 'MANUAL_VERIFICATION_REQUIRED',
            LOOKUPS_PAUSED: 'LOOKUPS_PAUSED',
            FROZEN_FOR_COB: 'FROZEN_FOR_COB',
            FROZEN_FOR_COB_USER_ACTION: 'FROZEN_FOR_COB_USER_ACTION',
            FROZEN_FOR_TERMED_PLAN: 'FROZEN_FOR_TERMED_PLAN',
            FROZEN_FOR_OON: 'FROZEN_FOR_OON',
            FROZEN_FOR_WAIVED_SESSION_MAX_HIT: 'FROZEN_FOR_WAIVED_SESSION_MAX_HIT',
            FROZEN_FOR_AUTOPAY_CX_ACTION: 'FROZEN_FOR_AUTOPAY_CX_ACTION',
            FROZEN_FOR_OTHER_REASON: 'FROZEN_FOR_OTHER_REASON',
            OUT_OF_NETWORK: 'OUT_OF_NETWORK',
            OON_FOR_PROVIDER_ADDRESS: 'OON_FOR_PROVIDER_ADDRESS',
            TERMINATED: 'TERMINATED',
            HAS_NO_MENTAL_HEALTH_BENEFITS: 'HAS_NO_MENTAL_HEALTH_BENEFITS',
            EAP_OUT_OF_SESSIONS: 'EAP_OUT_OF_SESSIONS',
            EAP_OUT_OF_DATE: 'EAP_OUT_OF_DATE'
    }
}
