/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderSpecialtyBookingPreference {
    APPOINTMENT = 'APPOINTMENT',
    INTAKE_CALL = 'INTAKE_CALL',
    DO_NOT_BOOK = 'DO_NOT_BOOK'
}


export const ProviderSpecialtyBookingPreferenceMetadata: EnumMetadata<ProviderSpecialtyBookingPreference> = {
    name: 'ProviderSpecialtyBookingPreference',
    values: {
            APPOINTMENT: 'APPOINTMENT',
            INTAKE_CALL: 'INTAKE_CALL',
            DO_NOT_BOOK: 'DO_NOT_BOOK'
    }
}
