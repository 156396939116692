import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { InsuranceReadinessIssueFrozenForAutopayRecoupsType } from '@headway/api/models/InsuranceReadinessIssueFrozenForAutopayRecoups';
import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefits';
import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserAction';
import { InsuranceReadinessIssueFrozenForOutOfNetworkPlanType } from '@headway/api/models/InsuranceReadinessIssueFrozenForOutOfNetworkPlan';
import { InsuranceReadinessIssueFrozenForTermedPlanType } from '@headway/api/models/InsuranceReadinessIssueFrozenForTermedPlan';
import { InsuranceReadinessIssueFrozenForWaivedSessionMaxHitType } from '@headway/api/models/InsuranceReadinessIssueFrozenForWaivedSessionMaxHit';
import { InsuranceReadinessIssueHasNoTelehealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueHasNoTelehealthBenefits';
import { InsuranceReadinessIssueInactiveBenefitsType } from '@headway/api/models/InsuranceReadinessIssueInactiveBenefits';
import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { InsuranceReadinessNoRemainingSessionsType } from '@headway/api/models/InsuranceReadinessNoRemainingSessions';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import {
  getRemainingFamilyInNetworkDeductible,
  getRemainingFamilyOutOfPocketCosts,
  getRemainingIndividualInNetworkDeductible,
  getRemainingIndividualOutOfPocketCosts,
  InsuranceStage,
  PaymentStructure,
  StageCompletionCriteria,
} from '@headway/shared/utils/insuranceUtils';

export const displayAsFamilyPlan = (
  eligibilityLookup: EligibilityLookupRead | undefined,
  stages: InsuranceStage[]
): boolean => {
  if (!eligibilityLookup) {
    return false;
  }
  const currentStageIdx = stages.findIndex((stage) => !stage.completed);
  const currentStage = stages[currentStageIdx];
  const previousStage: InsuranceStage | undefined = stages[currentStageIdx - 1];

  if (
    currentStage.stageCompletionCriteria ===
    StageCompletionCriteria.FAMILY_THRESHOLD
  ) {
    return true;
  } else if (
    currentStage.paymentStructure === PaymentStructure.FULLY_COVERED &&
    previousStage &&
    previousStage.stageCompletionCriteria ===
      StageCompletionCriteria.FAMILY_THRESHOLD
  ) {
    return true;
  } else if (
    eligibilityLookup &&
    previousStage &&
    previousStage.paymentStructure === PaymentStructure.DEDUCTIBLE &&
    previousStage.stageCompletionCriteria ===
      StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD &&
    getRemainingIndividualInNetworkDeductible(eligibilityLookup)! > 0 &&
    getRemainingFamilyInNetworkDeductible(eligibilityLookup) === 0
  ) {
    return true;
  } else if (
    eligibilityLookup &&
    currentStage.paymentStructure === PaymentStructure.FULLY_COVERED &&
    previousStage &&
    previousStage.stageCompletionCriteria ===
      StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD &&
    getRemainingIndividualOutOfPocketCosts(eligibilityLookup)! > 0 &&
    getRemainingFamilyOutOfPocketCosts(eligibilityLookup) === 0
  ) {
    return true;
  }

  return false;
};

export const getHasUnreadinessIssue = (
  issues?: UserAppointmentReadiness['insurance']
) => {
  if (!issues) {
    return false;
  }
  const insuranceIssueTypesSet = new Set(issues.map((issue) => issue.type));

  const issueChecks = [
    InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK,
    InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED,
    InsuranceReadinessIssueTerminatedType.TERMINATED,
    InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS,
    InsuranceReadinessIssueHasNoTelehealthBenefitsType.HAS_NO_TELEHEALTH_BENEFITS,
    InsuranceReadinessIssueInactiveBenefitsType.INACTIVE_BENEFITS,
    InsuranceReadinessIssueFrozenForWaivedSessionMaxHitType.FROZEN_FOR_WAIVED_SESSION_MAX_HIT,
    InsuranceReadinessIssueFrozenForAutopayRecoupsType.FROZEN_FOR_AUTOPAY_RECOUPS_CX_ACTION,
    InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType.FROZEN_FOR_COB_USER_ACTION,
    InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType.FROZEN_FOR_COB,
    InsuranceReadinessIssueFrozenForTermedPlanType.FROZEN_FOR_TERMED_PLAN,
    InsuranceReadinessIssueFrozenForOutOfNetworkPlanType.FROZEN_FOR_OON,
    InsuranceReadinessNoRemainingSessionsType.NO_REMAINING_COVERED_SESSIONS,
  ];

  return issueChecks.some((issue) => insuranceIssueTypesSet.has(issue));
};
