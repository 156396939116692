/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientAddressType {
    HOME = 'HOME',
    WORK = 'WORK',
    SCHOOL = 'SCHOOL',
    COMMUNITY = 'COMMUNITY',
    OTHER = 'OTHER'
}


export const PatientAddressTypeMetadata: EnumMetadata<PatientAddressType> = {
    name: 'PatientAddressType',
    values: {
            HOME: 'HOME',
            WORK: 'WORK',
            SCHOOL: 'SCHOOL',
            COMMUNITY: 'COMMUNITY',
            OTHER: 'OTHER'
    }
}
