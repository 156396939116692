/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum BillingType {
    INSURANCE = 'INSURANCE',
    SELF_PAY = 'SELF_PAY',
    EAP = 'EAP',
    TRANSITION = 'TRANSITION'
}


export const BillingTypeMetadata: EnumMetadata<BillingType> = {
    name: 'BillingType',
    values: {
            INSURANCE: 'INSURANCE',
            SELF_PAY: 'SELF_PAY',
            EAP: 'EAP',
            TRANSITION: 'TRANSITION'
    }
}
